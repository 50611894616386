
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from './Corona/Home/home';
import IndexPage from './Home/index';
import IndexLol from './LoL/Index';
import PachangaPage from './Pachanga/pachanga';
import PokerPage from "./Poker/poker";
import CoronaPage from './NewCorona/Home/home';
import CinePage from './Cine/Cine';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/corona" element={<CoronaPage />} />
        <Route path="/coronaOld" element={<HomePage />} />
        <Route path="/poker" element={<PokerPage />} />
        <Route path="/pachanga" element={<PachangaPage />} />
        <Route path="/lol" element={<IndexLol />} />
        <Route path="/cine" element={<CinePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
