import React, {useEffect, useState} from 'react';
import "./index.css"

export default function IndexLol() {

    useEffect(() => {
        call()
    }, [])

    const call = async ()=> {
        const res = await fetch("https://api.allorigins.win/get?url=https://www.flashscore.es/esports/league-of-legends/lec/"
            )
        const data = await res.json()
        console.log(data.contents);
    }
    return(
        <div>
        
        </div>
    )
}
