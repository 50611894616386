import React, { useEffect, useState } from "react";
import "./cine.css"

export default function CinePage() {

    return(
        <>
            <article className="cineContainer">
                <section className="cineElement">
                    <h1>El Avión 2</h1>
                    <iframe  src="https://www.youtube.com/embed/TyPX-v2P8fs?si=lPx52sCB4l-Us8F4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </section>
                <section className="cineElement">
                    <h1>Death Quack</h1>
                    <iframe  src="https://www.youtube.com/embed/cK6Q1_o5FpI?si=hr7fmexX13xhyWBo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </section>
                <section className="cineElement">
                    <h1>Debate Inglés</h1>
                    <iframe  src="https://www.youtube.com/embed/2lzOuqjF9_k?si=a4HlJAABf4U15s5c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </section>
                <section className="cineElement">
                    <h1>Nerd Kids</h1>
                    <iframe  src="https://www.youtube.com/embed/68aIe55pRbc?si=3_bv67hxKRKN4pYw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </section>
            </article>
        </>
    )
}