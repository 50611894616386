import React, {useEffect, useState} from 'react';
import "./index.css"
import atruoImg from "../assets/atruo.jpg"
import futbolSVG from "../assets/svg/futbol.svg"
import cineSVG from "../assets/svg/cine.svg"
import gameSVG from "../assets/svg/game.svg"

export default function IndexPage() {

    useEffect(() => {
        document.title = 'Atruo';
    }, [])

    return(
      <div style={{padding: "1em"}}>
        <div className='atruoHeader'>
            <img src={atruoImg} alt='atruo logo' className='atruoImg'/>
            <h1 className='atruoTitle'><span className='Aatruo'>A</span><span className='truoAtruo'>TRUO</span></h1>
        </div>    
        <div className='atruoLinks'>
            <button onClick={()=> window.location.href = "/pachanga"}>Pachanga <img src={futbolSVG} alt='futbol icono' className='iconLinkSVG'/></button>   
            <button onClick={()=> window.location.href = "/cine"}>Cine <img src={cineSVG} alt='poker icono' className='iconLinkSVG'/></button>   
            <button onClick={()=> window.location.href = "/corona"}>Corona <img src={gameSVG} alt='juego icono' className='iconLinkSVG'/></button>    
        </div>    
      </div>
    )
}
