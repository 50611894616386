import React, {useEffect, useState} from 'react';
import "./champs.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';




export default function ChampTable({ results }) {

    const [champs, setChamps] = useState([])

   useEffect(() => {
       refactor()
    }, [])
    
    const refactor = () => {
        let comodin = []
        results.map(res => {
            res.players.map(player => {
                let verify = false
                comodin.map((e, i) => {
                    if(e.champ === player.champ) {
                        comodin[i].played++
                        verify = true
                        if(!comodin[i].players.includes(player.name)) comodin[i].players.push(player.name)
                    } 
                })
                if (!verify) comodin.push({champ: player.champ, played: 1, players: [player.name]})
            })
        })
        
        comodin.sort((a,b) => (a.played < b.played) ? 1 : ((b.played < a.played) ? -1 : 0))
        comodin.length = 5
        console.log(comodin);
        setChamps(comodin)
    }

    return(
       <div className='champs-div'>            
            <table className='champs-table'>
                     <thead>
                        <tr>
                            <th>Champ</th>
                            <th>Jugado</th>
                            <th>Jugadores</th>

                        </tr>  
                        <br></br>
                    </thead>
                    <tbody>     
                        {    
                            champs.length > 0 &&                    
                            champs.map((champ, i) => {                            
                                return(
                                <tr key={i}>
                                    <td>{<img className='lol-icon' src={`http://ddragon.leagueoflegends.com/cdn/12.13.1/img/champion/${(champ.champ)}.png`}></img>}</td>
                                    <td><strong>{champ.played}</strong></td>
                                     <td>{champ.players.join(", ")}</td>
                                </tr>
                                )                            
                            })
                        }      
                    </tbody>                      
            </table>         
        </div>
    )
}
