import React, {useEffect, useState} from 'react';
import "./kda.css"
import { equipos } from '../equipos';

const numberPlayers = 25
export default function KdaTable({ results }) {

    const [kdas, setKdas] = useState([])
    const [topKda, setTopKda] = useState([0,0])
    const [topDpm, setTopDpm] = useState([0,0])
    const [kdasOrder, setKdasOrder] = useState([])
    const [kdasOrderCheck, setKdasOrderCheck] = useState(true)
    const [dpmsOrder, setDpmsOrder] = useState([])
    const [dpmsOrderCheck, setDpmsOrderCheck] = useState(true)

   useEffect(() => {
        refactor()
    }, [])
    
    const refactor = () => {    
        let comodin = equipos
      
        comodin.map((team, i) => {
            results.map((res)=> {
                if(team.equipo === res.blue) {
                    team.players.map((player, j) => {
                        comodin[i].players[j].games++
                        comodin[i].players[j].dpm = (player.dpm + res.players[j].dpm) 
                        comodin[i].players[j].kda = player.kda + (res.players[j].kills + res.players[j].assists) / (res.players[j].deaths > 0 ? res.players[j].deaths : 1)
                    })
                }else if(team.equipo === res.red){
                    team.players.map((player, j) => {                        
                        comodin[i].players[j].games++
                        comodin[i].players[j].dpm = (player.dpm + res.players[j+5].dpm) 
                        comodin[i].players[j].kda = player.kda + (res.players[j+5].kills + res.players[j+5].assists) / (res.players[j+5].deaths > 0 ? res.players[j+5].deaths : 1)
                    })
                }                
            })
        })
        setKdas(comodin)
        let topKdaComodin = [0,0,0]
        let topDpmComodin = [0,0,0]
        comodin.map((team, j)=>{
            team.players.map((player, i) => {
                comodin[j].players[i].dpm = comodin[j].players[i].dpm / comodin[j].players[i].games
                comodin[j].players[i].kda = comodin[j].players[i].kda / comodin[j].players[i].games
                if(player.kda > topKdaComodin[2]) topKdaComodin = [team.equipo, i, player.kda, player.name]
                if(player.dpm > topDpmComodin[2]) topDpmComodin = [team.equipo, i, player.dpm, player.name ]
            })
        })
        setTopKda(topKdaComodin)
        setTopDpm(topDpmComodin)
        console.log(comodin);
    }
    const orderKDA = () => {
        setKdasOrderCheck(!kdasOrderCheck)
        setDpmsOrder([])
        if(kdasOrderCheck) {
            let comodin = []
            kdas.map(team => {
                team.players.map(player => {
                    let x = player
                    x.team = team.equipo
                    comodin.push(x)
                })
            })
        
            comodin.sort((a,b) => (a.kda < b.kda) ? 1 : ((b.kda < a.kda) ? -1 : 0))
            
            setKdasOrder(comodin);   
        }else{
            setKdasOrder([])
        }
        
    }
    const orderDPM = () => {
        setKdasOrder([])
        setDpmsOrderCheck(!dpmsOrderCheck)
        if(dpmsOrderCheck) {
            let comodin = []
            kdas.map(team => {
                team.players.map(player => {
                    let x = player
                    x.team = team.equipo
                    comodin.push(x)
                })
            })
        
            comodin.sort((a,b) => (a.dpm < b.dpm) ? 1 : ((b.dpm < a.dpm) ? -1 : 0))
            
            setDpmsOrder(comodin);   
        }else{
            setDpmsOrder([])
        }
        
    }
    return(
       <div className='kdaTable'>
            {
                kdas.length > 0 &&
                <table className='kda-list' id='kda-table' >
                    <thead>
                        
                        <br></br>
                        <tr>
                            <th>Nombre</th>
                            <th className='order-kda' onClick={orderKDA}>KDA</th>
                            <th className='order-kda' onClick={orderDPM}>DPM</th>
                        </tr>
                    </thead>
                    <tbody >          
                        {
                            kdas.length > 0 && kdasOrder.length == 0 && dpmsOrder.length == 0 &&
                            kdas.map((team, i) =>{                                
                                return(
                                     team.players.map((player, j) => (
                                        <tr key={j+i} className={`kda-item team-${i}`}>
                                            <th><small>{player.name}</small></th>
                                            <th className={topKda[0] === team.equipo && topKda[1] === j ? "topKda" : ""}><small>{player.kda.toFixed(2)}</small> {topKda[0] === team.equipo && topKda[1] === j ? <span>⭐</span> : ""}</th>
                                            <th className={topDpm[0] === team.equipo && topDpm[1] === j ? "topdpm" : ""}><small>{player.dpm.toFixed(2)}</small> {topDpm[0] === team.equipo && topDpm[1] === j ? <span>⭐</span> : ""}</th>
                                        </tr>
                                    ))
                                )
                               
                            })
                        }
                        {
                            kdas.length > 0 && kdasOrder.length > 0 &&
                            kdasOrder.map((player, i) =>{                                
                                return(                                    
                                    <tr key={i} className={`kda-item team-${player.team == "La envidia de la grieta" ? 0 : player.team == "Negres Betanics" ? 1 : player.team == "Los tentáculos de Juan" ? 2 : player.team == "Ángeles de Riki" ? 3 : player.team == "El fuking loko" ? 4 : -1}`}>
                                        <th><small>{player.name}</small></th>
                                        <th ><small>{player.kda.toFixed(2)}</small> {topKda[3] === player.name   ? <span>⭐</span> : ""}</th>
                                        <th ><small>{player.dpm.toFixed(2)}</small> {topDpm[3] === player.name    ? <span>⭐</span> : ""}</th>
                                    </tr>
                                    
                                )
                               
                            })
                        }
                        {
                            kdas.length > 0 && dpmsOrder.length > 0 &&
                            dpmsOrder.map((player, i) =>{                                
                                return(                                    
                                    <tr key={i} className={`kda-item team-${player.team == "La envidia de la grieta" ? 0 : player.team == "Negres Betanics" ? 1 : player.team == "Los tentáculos de Juan" ? 2 : player.team == "Ángeles de Riki" ? 3 : player.team == "El fuking loko" ? 4 : -1}`}>
                                        <th><small>{player.name}</small></th>
                                        <th ><small>{player.kda.toFixed(2)}</small> {topKda[3] === player.name   ? <span>⭐</span> : ""}</th>
                                        <th ><small>{player.dpm.toFixed(2)}</small> {topDpm[3] === player.name    ? <span>⭐</span> : ""}</th>
                                    </tr>
                                    
                                )
                               
                            })
                        }
                        
                        
                    </tbody>                      
                </table>
            }
            
        </div>
    )
}
