import React, {useEffect, useState} from 'react';

export default function TablaClasificacion({ results }) {

    const [rows, setRows] = useState([])  
   
    useEffect(() => {          
        refactor()        
    }, [] )   

    const refactor = ()=> {
        let tentaculos = [0,0]    
        let envidia = [0,0] 
        let loko = [0,0] 
        let betanics = [0,0] 
        let riki = [0,0]            
        results.map((res) =>{                      
            try {
                //win
                if(res.winner.toLocaleLowerCase().includes("tentáculos")) tentaculos[0]++ 
                if(res.winner.toLocaleLowerCase().includes("envidia")) envidia[0]++ 
                if(res.winner.toLocaleLowerCase().includes("loko")) loko[0]++ 
                if(res.winner.toLocaleLowerCase().includes("betanics")) betanics[0]++ 
                if(res.winner.toLocaleLowerCase().includes("riki")) riki[0]++ 
                //lose
                if(res.loser.toLocaleLowerCase().includes("tentáculos")) tentaculos[1]++ 
                if(res.loser.toLocaleLowerCase().includes("envidia")) envidia[1]++ 
                if(res.loser.toLocaleLowerCase().includes("loko")) loko[1]++ 
                if(res.loser.toLocaleLowerCase().includes("betanics")) betanics[1]++ 
                if(res.loser.toLocaleLowerCase().includes("riki")) riki[1]++                
            } catch (error) {                
            }
        }) 
        let como =    [    
                { id: 1, Nombre: 'Negres Betanics', V: betanics[0], D: betanics[1] },
                { id: 2, Nombre: 'Los tentáculos de Juan', V: tentaculos[0], D: tentaculos[1] },
                { id: 3, Nombre: 'La envidia de la grieta', V: envidia[0], D: envidia[1] },
                { id: 4, Nombre: 'El fuking loko', V: loko[0], D: loko[1] },
                { id: 5, Nombre: 'Ángeles de Riki', V: riki[0], D: riki[1] },  
        ]
        como.sort((a,b) => (a.V < b.V) ? 1 : ((b.V < a.V) ? -1 : 0))      
        setRows(
            como
        )
    }

    return(
        <div className='clasificacion-div'>
            {
                rows.length > 0 &&
                <table className='clasificacion'>
                     <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>V</th>
                            <th>D</th>
                        </tr>  
                    </thead>
                    <tbody>          
                        {                        
                            rows.map((team, i) => {                            
                                return(
                                <tr key={i}>
                                    <td style={{width: "200px",}}>{team.Nombre}</td>
                                    <td className={`result ${team.V > team.D ? "win" : team.V < team.D ? "lose" : ""}`}>{team.V}</td>
                                    <td className={`result ${team.V > team.D ? "win" : team.V < team.D ? "lose" : ""}`}>{team.D}</td>
                                </tr>
                                )                            
                            })
                        }  
                    </tbody>                      
                </table>
            }
            
        </div>
    )
}
