import React, { useEffect, useState } from "react";
import "./pachanga.css";
import camiseta from "../assets/camiseta.png"
import bg from "../assets/fondo-min.png";

export default function PachangaPage() {
  useEffect(() => {
    document.title = "Pachanga";
    
  }, []);

  const [team1, setTeam1] = useState([])
  const [team2, setTeam2] = useState([]);
  const [color1, setColor1] = useState([]);
  const [color2, setColor2] = useState([]);
  const [team3, setTeam3] = useState([]);
  const [color3, setColor3] = useState([]);
  const [team3Check, setTeam3Check] = useState(false);

  const shuffle = (arr) => {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    return arr;
  };
  const splitArray = (arr) => {
    let firstHalf = [];
    let secondHalf = [];
    let thirdHalf = [];

    if(team3Check) {
        firstHalf = arr.slice(0, arr.length / 3);
        secondHalf = arr.slice(arr.length / 3, (2 * arr.length) / 3);
        thirdHalf = arr.slice((2 * arr.length) / 3);
        console.log(firstHalf, secondHalf, thirdHalf);
        return [firstHalf, secondHalf, thirdHalf];
    }else {
        arr.forEach((element, index) => {
          if (index < arr.length / 2) {
            firstHalf.push(element);
          } else {
            secondHalf.push(element);
          }
        });

        return [firstHalf, secondHalf];
    }
    
  };

  const makeTeams = ()=> {
    let lista = document.getElementById("listaParticipantes").value;
    let colores = document.getElementById("listaColores").value;
    lista = lista.split(/\r?\n/);   
    lista = lista.filter((element) => {
      return element !== "";
    });
    colores = colores.split(/\r?\n/).filter((element) => element !== "");

    if(team3Check) {
        const [team1X, team2X, team3X] = splitArray(shuffle(lista));
        const [color1X, color2X, color3X] = splitArray(shuffle(colores));
        setColor1(color1X);
        setColor2(color2X);
        setColor3(color3X);
        setTeam1(team1X);
        setTeam2(team2X);
        setTeam3(team3X);

    }else{
        const [team1X, team2X] = splitArray(shuffle(lista));
        const [color1X, color2X] = splitArray(shuffle(colores));
        setColor1(color1X);
        setColor2(color2X);
        setTeam1(team1X);
        setTeam2(team2X);
        console.log(team1, color1, team2, color2);
    }
    
  }

  const changeCheckbox = ()=> {
    setTeam3Check(!team3Check);
    let colors = document.getElementById("listaColores");
    let vals = colors.value.split(/\r?\n/).filter((element) => element !== "");
    if (vals.length < 3 && !team3Check) colors.value = colors.value + "\nNegro";
    if (vals.length === 3 && team3Check) colors.value = "Blanco\nAzul";
  }

  return (
    <div className="cesped">
      <div className="containerPachanga">
        <div className={`lista ${team1.length > 0 ? "listaHide" : ""}`}>
          <h2 className="title-pachanga">Jugadores</h2>
          <textarea rows="20" cols="30" id="listaParticipantes"></textarea>
          <h2 className="title-pachanga">Colores</h2>
          <textarea
            rows="3"
            cols="30"
            id="listaColores"
            defaultValue={`Blanco\nAzul`}
          ></textarea>
          <label className="title-pachanga">
            <input
              type="checkbox"
              id="3team"
              value="first_checkbox"
              checked={team3Check}
              onChange={() => changeCheckbox()}
            />{" "}
            3 equipos
          </label>
          <button className="makeTeamsBtn" onClick={() => makeTeams()}>
            HACER EQUIPOS
          </button>
        </div>
        {team1.length > 0 && (
          <div className="resultadosEquipos">
            <>
              <span
                className="closeTeams"
                onClick={() => {
                  setTeam1([]);
                  setTeam2([]);
                  setTeam3([]);
                }}
              >
                X
              </span>
              <div className="Equipo 1">
                <h2>Equipo 1</h2>
                <p className="camisetaTxt">
                  <img
                    src={camiseta}
                    alt="camiseta"
                    className="camisetaImg"
                  ></img>
                  : {color1}
                </p>
                {team1.map((e) => (
                  <li>{e}</li>
                ))}
              </div>
              <br></br>
              <hr></hr>

              <div className="Equipo 2">
                <h2>Equipo 2</h2>
                <p className="camisetaTxt">
                  <img
                    src={camiseta}
                    alt="camiseta"
                    className="camisetaImg"
                  ></img>
                  : {color2}
                </p>
                {team2.map((e) => (
                  <li>{e}</li>
                ))}
              </div>

              {team3Check && (
                <>
                  <br></br>
                  <hr></hr>
                  <div className="Equipo 3">
                    <h2>Equipo 3</h2>
                    <p className="camisetaTxt">
                      <img
                        src={camiseta}
                        alt="camiseta"
                        className="camisetaImg"
                      ></img>
                      : {color3}
                    </p>
                    {team3.map((e) => (
                      <li>{e}</li>
                    ))}
                  </div>
                </>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
}
