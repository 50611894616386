import React, { useEffect, useState } from "react";
import "./poker.css";
import bg from "../assets/poker-bg.jpg";

export default function PokerPage() {

  const [apuesta, setApuesta] = useState(5);
  const [participantes, setParticipantes] = useState([
    {
      id: 1,
      nombre: "A",
      cantidad: 10,
    },
    {
      id: 2,
      nombre: "B",
      cantidad: 7,
    },
    {
      id: 3,
      nombre: "C",
      cantidad: 3,
    },
    {
      id: 4,
      nombre: "D",
      cantidad: 0,
    },
    {
      id: 5,
      nombre: "E",
      cantidad: 2,
    },
    {
      id: 6,
      nombre: "F",
      cantidad: 1,
    },
    {
      id: 7,
      nombre: "G",
      cantidad: 12,
    },
  ]);  
  const [deudas, setDeudas] = useState([])

  useEffect(() => {
    document.title = "Poker";
   
  }, []);


const removeParticipante = (id) => {
    setParticipantes(participantes.filter(participante => participante.id !== id))
}
const addPoker = ()=> {

    let numeroAleatorio = Math.random() * 10000000000000;
    let nombre = document.getElementById("nombrePoker").value
    let cantidad = document.getElementById("cantidadPoker").value
    setParticipantes([
      ...participantes,
      {
        id: numeroAleatorio, 
        nombre: nombre,
        cantidad: cantidad
      }
      
    ]);
    document.getElementById("nombrePoker").value = "";
    document.getElementById("cantidadPoker").value = "";
}


const calcular = async ()=> {
  let winners = [];
  let losers = [];
  participantes.forEach((part) => {
    if (part.cantidad - apuesta > 0) {
      winners.push({
        id: part.id,
        nombre: part.nombre,
        cantidad: part.cantidad - apuesta,
      });
    } else if (part.cantidad - apuesta < 0) {
      losers.push({
        id: part.id,
        nombre: part.nombre,
        cantidad: part.cantidad - apuesta,
      });
    }
  });
  winners.sort((a, b) => a.cantidad - b.cantidad, true);
  losers.sort((a, b) => b.cantidad - a.cantidad, true);

  // Filtrar solo los jugadores que tienen pérdidas
  //const jugadoresConPerdidas = datos.filter(jugador => jugador.Cantidad < 0);

  // Ordenar los jugadores por pérdida ascendente
  //losers.sort((a, b) => a.cantidad - b.cantidad);

  let pagos = [];

  for (const jugadorPerdedor of losers) {
    let cantidadPagar = Math.abs(jugadorPerdedor.cantidad);
    for (const jugadorGanador of winners) {
      if (jugadorGanador.cantidad > 0) {
        const cantidadRecibir = Math.min(cantidadPagar, jugadorGanador.cantidad);
        if (cantidadRecibir > 0) {
          pagos.push({
            De: jugadorPerdedor.nombre,
            Para: jugadorGanador.nombre,
            Cantidad: cantidadRecibir
          });
          cantidadPagar -= cantidadRecibir;
          jugadorGanador.cantidad -= cantidadRecibir;
        }
      }
      if (cantidadPagar === 0) {
        break;
      }
    }
  }
  console.log(pagos);
  setDeudas(pagos)

}


  return (
    <div className="pokerContainer">
      <div className="apuestaPoker">
        <span className="colorStyle">Apuesta: </span>
        <input
          type="text"
          placeholder={apuesta}
          defaultValue={5}
          onChange={(e) => setApuesta(e.target.value)}
        ></input>
        <span className="colorStyle"> €</span>
      </div>

      <div className="pokerTable" id="pokerTable">
        <div className="rowPokerHeader">
          <div className="colorStyle">Jugador</div>
          <div className="colorStyle">Ganado</div>
        </div>
        {participantes.length > 0 &&
          participantes.map((part) => (
            <div class="rowPoker" id={part.id} key={part.id}>
              <span type="text">{part.nombre}</span>
              <span type="text">{part.cantidad}</span>
              <div
                className="colorStyle"
                onClick={() => removeParticipante(part.id)}
              >
                X
              </div>
            </div>
          ))}
      </div>
      <div className="addPokerContainer">
        <div>
          <input type="text" id="nombrePoker"></input>
          <input type="text" id="cantidadPoker"></input>
        </div>

        <button className="addPoker" onClick={addPoker}>
          Añadir Participante
        </button>
      </div>
      <button className="addPoker separaPoker" onClick={() => calcular()}>
        Calcular
      </button>

      {deudas.length > 0 &&      
        <div className="deudasPoker">
          {deudas.map((deuda) => (
              <span>
                {deuda.De} debe {deuda.Cantidad}€ a {deuda.Para}
              </span>
            ))}
        </div>
      }
    </div>
  );
}
