const equipos = [
    {
        equipo: "Team 1",
        players: [
            {
                name: "Mario",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Jimenez",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Ferrer",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Atruo",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Raúl",
                kda: 0,
                games: 0,
                dpm: 0
            },

        ]
    },
    {
        equipo: "Team 2",
        players: [
            {
                name: "Rosell",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Jorge",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Pepo",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Caruana",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Pablo",
                kda: 0,
                games: 0,
                dpm: 0
            },

        ]
    },
    {
        equipo: "Team 3",
        players: [
            {
                name: "Moltó",
                kda: 0,
                games: 0,
                dpm: 0
            },
            {
                name: "Alex",
                kda: 0,
                        games: 0,
                dpm: 0
            },
            {
                name: "Andaluz",
                kda: 0,
                        games: 0,
                dpm: 0
            },
            {
                name: "Moreno",
                kda: 0,
                        games: 0,
                dpm: 0
            },
            {
                name: "Riki",
                kda: 0,
                        games: 0,
                dpm: 0
            },

        ]
    }
]

export { equipos }