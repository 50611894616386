import React, {useEffect, useState} from 'react';
import "./clasificacion.css"

export default function TablaClasificacion({ results }) {

    const [rows, setRows] = useState([])  
   
    useEffect(() => {          
        refactor()        
    }, [] )   

    const refactor = ()=> {
        let team1 = [0,0]    
        let team2 = [0,0] 
        let team3 = [0,0] 
                   
        results.map((res) =>{                      
            try {
                //win
                if(res.winner.toLocaleLowerCase().includes("1")) team1[0]++ 
                if(res.winner.toLocaleLowerCase().includes("2")) team2[0]++ 
                if(res.winner.toLocaleLowerCase().includes("3")) team3[0]++ 
             
                //lose
                if(res.loser.toLocaleLowerCase().includes("1")) team1[1]++ 
                if(res.loser.toLocaleLowerCase().includes("2")) team2[1]++ 
                if(res.loser.toLocaleLowerCase().includes("3")) team3[1]++ 
                              
            } catch (error) {                
            }
        }) 
        let como =    [    
                { id: 1, Nombre: 'Team 1', V: team1[0], D: team1[1] },
                { id: 2, Nombre: 'Team 2', V: team2[0], D: team2[1] },
                { id: 3, Nombre: 'Team 3', V: team3[0], D: team3[1] },               
        ]
        como.sort((a,b) => (a.V < b.V) ? 1 : ((b.V < a.V) ? -1 : 0))      
        setRows(
            como
        )
    }

    return(
        <>
            {
                rows.length > 0 &&
                <table className='clasificacion2'>
                     <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>V</th>
                            <th>D</th>
                        </tr>  
                    </thead>
                    <tbody>          
                        {                        
                            rows.map((team, i) => {                            
                                return(
                                <tr key={i}>
                                    <td style={{width: "200px",}}>{team.Nombre}</td>
                                    <td className={`result ${team.V > team.D ? "win" : team.V < team.D ? "lose" : ""}`}>{team.V}</td>
                                    <td className={`result ${team.V > team.D ? "win" : team.V < team.D ? "lose" : ""}`}>{team.D}</td>
                                </tr>
                                )                            
                            })
                        }  
                    </tbody>                      
                </table>
            }
            
        </>
    )
}
