import React, {useEffect, useState} from 'react';
import "./resultados.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: "fit-content",
  bgcolor: 'white',
  boxShadow: 24,
  p: 4,
 '&:hover,&:focus': { 
       border: "none",
    },
};

export default function TablaResultados({results}) {  
    const [open, setOpen] = React.useState(false);
    const [game, setGame] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);  
    const capitalizeFirstLetter = (string)=> {       
        
        if(string == "TAHMKENCH" ) {
            return("TahmKench")
        }else if(string == "DRMUNDO") {
            return("DrMundo")
        }
        else if(string == "KOGMAW") {
            return("KogMaw")
        }
        else if(string == "KOGMAW") {
            return("KogMaw")
        }else if(string == "XINZHAO") {
            return("XinZhao")
        }
        else if(string == "LEESIN") {
            return("LeeSin")
        }
        else if(string == "MASTERYI") {
            return("MasterYi")
        }
        else{
            string = string.toLowerCase()
            return string.charAt(0).toUpperCase() + string.slice(1);

        }
    }

    return(
       <div className='resultados-div'>
        <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >  
                 <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 700 }} aria-label="simple table" >
                        <TableHead sx={{backgroundColor: "rgba(73, 226, 226, 0.2)", fontWeight: "bold"}}>
                            <TableRow>
                                <TableCell align="left"><strong> </strong></TableCell>
                                <TableCell align="left"><strong>Player</strong></TableCell>
                                <TableCell align="right"><strong>K</strong></TableCell>
                                <TableCell align="right"><strong>D</strong></TableCell>
                                <TableCell align="right"><strong>A</strong></TableCell>
                                <TableCell align="right"><strong>DPM</strong></TableCell>
                                <TableCell align="right" sx={{width: "5px"}} ><small><strong>{game ? game.time : ""}</strong> min.</small></TableCell>
                                <TableCell align="left"><strong>DPM</strong></TableCell>
                                <TableCell align="right"><strong>K</strong></TableCell>
                                <TableCell align="right"><strong>D</strong></TableCell>
                                <TableCell align="right"><strong>A</strong></TableCell>                        
                                <TableCell align="right"><strong>Player</strong></TableCell>   
                                <TableCell align="left"><strong> </strong></TableCell>                         
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {game!= null &&
                            game.players.map((player, i) => (
                                i<5&&
                                <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell className={game.winner === game.blue ? "winner-game" : "loser-game"} component="th" scope="row"><img className='lol-icon' src={`http://ddragon.leagueoflegends.com/cdn/12.13.1/img/champion/${(player.champ)}.png`}></img> </TableCell>    
                                <TableCell className={game.winner === game.blue ? "winner-game" : "loser-game"} component="th" scope="row">{player.name} <small className='mvp-res'>{game.MVP === player.name ? "MVP  " : ""}</small></TableCell>
                                <TableCell className={game.winner === game.blue ? "winner-game" : "loser-game"} align="right">{player.kills}</TableCell>
                                <TableCell className={game.winner === game.blue ? "winner-game" : "loser-game"} align="right">{player.deaths}</TableCell>
                                <TableCell className={game.winner === game.blue ? "winner-game" : "loser-game"} align="right">{player.assists}</TableCell>
                                <TableCell className={game.winner === game.blue ? "winner-game" : "loser-game"} align="right">{Math.floor(player.dpm)}</TableCell>
                                <TableCell align="center">vs</TableCell>
                                <TableCell className={game.winner === game.red ? "winner-game" : "loser-game"} align="left">{Math.floor(game.players[i+5].dpm)}</TableCell>
                                <TableCell className={game.winner === game.red ? "winner-game" : "loser-game"} align="right">{game.players[i+5].kills}</TableCell>                                
                                <TableCell className={game.winner === game.red ? "winner-game" : "loser-game"} align="right">{game.players[i+5].deaths}</TableCell>
                                <TableCell className={game.winner === game.red ? "winner-game" : "loser-game"} align="right">{game.players[i+5].assists}</TableCell>
                                <TableCell className={game.winner === game.red ? "winner-game" : "loser-game"} align="right"><small className='mvp-res'>{game.MVP === game.players[i+5].name ? "MVP  " : ""}</small>{game.players[i+5].name} </TableCell>
                                <TableCell className={game.winner === game.red ? "winner-game" : "loser-game"} component="th" scope="row"><img className='lol-icon' src={`http://ddragon.leagueoflegends.com/cdn/12.13.1/img/champion/${(game.players[i+5].champ)}.png`}></img> </TableCell> 
                                </TableRow>
                            ))}
                           
                        </TableBody>
                    </Table>
                    </TableContainer>
              
                    
               
               
              </Box>  
              
            </Modal>
            {
                results.length > 0 &&
                <table className='resultados'>
                    <thead>
                        <caption className='title-resultados'><strong>Resultados</strong></caption>
                        
                    </thead>
                    <tbody>          
                        {                        
                            results.map((res, i) => {                            
                                return(
                                    <>
                                        <tr key={i} className="results-list" 
                                        onClick={()=> {
                                            setGame(res)
                                            console.log(res);
                                            handleOpen()
                                            }}>
                                            <td>{res.blue}</td>
                                            <td className={`number ${res.blue === res.winner ? "winner" : ""}`}>{res.blue === res.winner ? <strong>W</strong> : "L"}</td>
                                            <td className={`number ${res.red === res.winner ? "winner" : ""}`}>{res.red === res.winner ? <strong>W</strong> : "L"}</td>
                                            <td>{`  ${res.red}`}</td>
                                        </tr>                                        
                                    </>
                                )                            
                            })
                        }  
                    </tbody>                      
                </table>
            }
            
        </div>
    )
}
