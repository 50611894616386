import React, {useEffect, useState} from 'react';
import ChampTable from '../Champs/champs';
import TablaClasificacion from '../Clasificación/clasificacion';
import KdaTable from '../KDA/kda';
import TablaResultados from '../Resultados/resultados';
import "./home.css"



export default function CoronaPage() {

    const [results, setResults] = useState([])
    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {

        const sheetId = '1FAwbo7m8wsEv5MerGgsVc9h6DdURorju1Y_jRtKVrVw';
        const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
        const sheetName = 'DB';
        const query = encodeURIComponent('Select *')
        const url = `${base}&sheet=${sheetName}&tq=${query}&range=A1:BN30`
        const res = await fetch(url)
        const txt = await res.text()        

        let x = txt.split("(")[1]
        x = x.split(',"parsedNumHeaders":1}});')[0] + "}}"
        x = JSON.parse(x)
        let cols = x.table.cols
        let rows = x.table.rows
        //console.log(x);
        let data = []
        rows.map(row => {
            let dat = row.c
            data.push({
                "winner": dat[0].v,
                "loser": dat[1].v,
                "type": "liga",
                "red": dat[2].v,
                "blue": dat[3].v,
                "time": dat[4].v,
                "MVP": dat[55].v,
                players: [
                    {
                        "name": dat[5].v,
                        "kills": dat[6].v,
                        "deaths": dat[7].v,
                        "assists": dat[8].v,
                        "dpm": dat[9].v,  
                        "champ": dat[56].v                     
                    },
                    {
                        "name": dat[10].v,
                        "kills": dat[11].v,
                        "deaths": dat[12].v,
                        "assists": dat[13].v,
                        "dpm": dat[14].v,  
                        "champ": dat[57].v                           
                    },
                    {
                        "name": dat[15].v,
                        "kills": dat[16].v,
                        "deaths": dat[17].v,
                        "assists": dat[18].v,
                        "dpm": dat[19].v,  
                        "champ": dat[58].v                           
                    },
                    {
                        "name": dat[20].v,
                        "kills": dat[21].v,
                        "deaths": dat[22].v,
                        "assists": dat[23].v,
                        "dpm": dat[24].v,   
                        "champ": dat[59].v                          
                    },
                    {
                        "name": dat[25].v,
                        "kills": dat[26].v,
                        "deaths": dat[27].v,
                        "assists": dat[28].v,
                        "dpm": dat[29].v,  
                        "champ": dat[60].v                           
                    },
                    {
                        "name": dat[30].v,
                        "kills": dat[31].v,
                        "deaths": dat[32].v,
                        "assists": dat[33].v,
                        "dpm": dat[34].v,  
                        "champ": dat[61].v                           
                    },
                    {
                        "name": dat[35].v,
                        "kills": dat[36].v,
                        "deaths": dat[37].v,
                        "assists": dat[38].v,
                        "dpm": dat[39].v,  
                        "champ": dat[62].v                           
                    },
                    {
                        "name": dat[40].v,
                        "kills": dat[41].v,
                        "deaths": dat[42].v,
                        "assists": dat[43].v,
                        "dpm": dat[44].v,   
                        "champ": dat[63].v                          
                    },
                    {
                        "name": dat[45].v,
                        "kills": dat[46].v,
                        "deaths": dat[47].v,
                        "assists": dat[48].v,
                        "dpm": dat[49].v,  
                        "champ": dat[64].v                           
                    },
                    {
                        "name": dat[50].v,
                        "kills": dat[51].v,
                        "deaths": dat[52].v,
                        "assists": dat[53].v,
                        "dpm": dat[54].v,   
                        "champ": dat[65].v                          
                    },
                ]
            })
        })
        console.log(data);
        setResults(data)
    }
    
    return(        
            
        results.length > 0 &&
        <div className='containerCorona'>
            <div className='firstHalf'>
                <TablaClasificacion results = {results} />
                 <TablaResultados results = {results} /> 
                {/* <ChampTable results = {results} /> */}
            </div>
            <div style={{backgroundColor: "rgb(73 71 68 / 47%)", padding: "15px", borderRadius: "15px"}}>
                <KdaTable results = {results} />
            </div>
            
            
        </div>          
            
        
    )
}
